import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../helpers/history';
import { Link } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import ROLES from '../../../constants/roles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import BlockedUserModal from './modals/BlockedUserModal';
import { signIn, resetError } from '../../../store/actions/authActions';
import browserTabTitleOverride from '../../../helpers/tabTitleOverride';

import './Login.sass';

const MySwal = withReactContent(Swal);

class Login extends Component {
  state = {
    email: localStorage.getItem('bufferEmail')
      ? localStorage.getItem('bufferEmail')
      : '',
    password: '',
    isShowBlockedUserModal: false,
    error: '',
    };

    componentDidMount() {
      browserTabTitleOverride();
      this.setState({
        error: typeof this.props.error === 'string' && this.props.error,
        isShowBlockedUserModal: this.props.error === 'Your account is blocked, please, contact admin'
        || localStorage.getItem('isBlocked')
      });
    }

    componentWillUnmount() {
        localStorage.getItem('bufferEmail') !== null &&
        localStorage.removeItem('bufferEmail');
        this.props.resetError();
    }

    showModal = () => {
    MySwal.fire({
        html: <BlockedUserModal />,
        title: 'Sorry, you have been blocked',
        text: 'Are you sure that you want to see passed events',
        confirmButtonText: 'Got it',
        customClass: 'blocked-user-modal',
    }).then((result) => {
        if (result.value ||
            result.dismiss === 'overlay' ||
            result.dismiss === 'esc') {
            localStorage.removeItem('isBlocked');
            this.setState({ isShowBlockedUserModal: false,
              email: localStorage.getItem('bufferEmail') ? localStorage.getItem('bufferEmail') : '',
              password: '',
              error: localStorage.getItem('bufferEmail') ? this.props.error : null
            });
            }
        });
    };

    inputChangeHandler = (event) => {
        const { name, value } = event.target;
        !localStorage.getItem('isBlocked') && this.setState({ [name]: value });
    };

    signInHandler = () => {
        const { email, password } = this.state;
        const { signIn } = this.props;

        const data = {
            email,
            password,
            errors: [],
            error: '',
        };

        signIn(data).then((result) => {
            if (result) {
                if (result.role === ROLES.PROMOTER) {
                    history.push(ROUTES.EVENTS);
                } else {
                    history.push(ROUTES.PROMOTERS);
                }
            }
        });
    };

    render() {
        const { error } = this.state;

    return (
        <div className="auth login">
        {this.state.isShowBlockedUserModal && this.showModal()}
        <img
          className="auth__logo"
          src="/images/img-logo.svg"
          alt="SixTix logo"
        />
        <div className="auth__title">Sign in to your account</div>
        <form>
          <p className="form-error">{error}</p>
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={this.inputChangeHandler}
            value={this.props.error && this.state.email}
          />
          <div className="auth__forgot-password">
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.inputChangeHandler}
              value={this.props.error && this.state.password}
            />
            <Link
              className="auth__forgot-password__link"
              to={ROUTES.RECOVER_PASSWORD}>
              Forgot password?
            </Link>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.signInHandler}>
            Sign in
          </button>
        </form>
        <Link className="d-block auth__link" to={ROUTES.REGISTRATION}>
          Registration
        </Link>
        <p className="mark">1144-web-save-the-parameter-ignore-this-line-for-the-header-of-the-file-for-the-current-provider</p>
      </div>
    );
  }
}

Login.propTypes = {};

const mapStateToProps = ({ auth }) => ({ error: auth.error });

const mapDispatchToProps = (dispatch) => ({
  signIn: (data) => {
    return dispatch(signIn(data));
  },
  resetError: () => {
    return dispatch(resetError());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
