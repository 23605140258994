import React, { Component } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Redirect } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import withRouter from 'react-router/es/withRouter';
import AsyncSelect from 'react-select/lib/Async';

import TicketRule from '../../../../../components/Events/TicketRule/TicketRule';
import Icon from '../../../../../components/UI/Icon/Icon';
import ROUTES from '../../../../../constants/routes';
import {
  clearCurrentEvent,
  createTicket,
  createTicketRule,
  getEvent,
  getTicket,
  removeTicket,
  removeTicketRule,
  updateTicket,
  updateTicketRule,
} from '../../../../../store/actions/eventsActions';
import { getTemplates } from "../../../../../store/actions/canvasActions";
import NewLoader from '../../../../../components/NewLoader/NewLoader';
import { Counter } from '../../../../../components/Counter/Counter';
import numberNormalize from '../../../../../helpers/priceNormalize';
import getRandomArbitrary from '../../../../../helpers/getRandomId';
import CheckBox from '../../../../../components/UI/Checkbox/CheckBox';

import './TicketDetail.sass';
import { getPdfTemplatesAsyncFunc } from '../../../../../helpers/getPdfTemplatesAsyncFunc';

const MySwal = withReactContent(Swal);

class TicketDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddTicket: true,
      startDate: null,
      endDate: null,
      count: 1,
      capacity: '',
      ticketName: '',
      pdfTemplateNameOption: {},
      ticketPrice: '',
      ticketRules: [],
      ticketId: null,
      seatsIoCategoryKey: '',
      isFound: true,
      hasSeats: true,
      entranceName: '',
      isAvailableToSale: true,
      isAvailableToResale: true,
    };
  }

  checkboxChange = (evt) => {
    const { name, checked } = evt.target;

    this.setState({ [name]: checked });
  };

  inputChangeHandle = (event) => {
    event.target.value = numberNormalize(event.target.value);
    this.setState({ ticketPrice: event.target.value });
  };

  inputCapacityChangeHandle = (event) => {
    event.target.value = event.target.value.replace(/[^\d.]/g, '');

    this.setState({ capacity: event.target.value });
  };

  inputPdfTemplateNameChangeHandle = (value) => {
    this.setState({ pdfTemplateNameOption: value });
  };

  inputEntranceNameChangeHandle = (event) => {
    this.setState({ entranceName: event.target.value });
  };

  inputSeatsIoCategoryKeyChangeHandle = (event) => {
    event.target.value = event.target.value.replace(/[^\d.]/g, '');

    this.setState({ seatsIoCategoryKey: event.target.value });
  };

  handleChangeCount = (value) =>
    this.setState({
      count: value,
    });

  goToPage = (route) => {
    this.props.history.push({
      pathname: route
        .replace(':id', this.props.match.params.id)
        .replace(':ticket_id', this.props.match.params.ticket_id),
    });
  };

  goToUploadBarcodes = () => {
    this.props.history.push({
      pathname: ROUTES.NEW_ADD_BAR_CODE.replace(
        ':id',
        this.props.match.params.id
      ).replace(':ticket_id', this.props.match.params.ticket_id),
    });
  };

  ticketNameChangeHandler = (event) => {
    this.setState({ ticketName: event.target.value });
  };

  componentDidMount() {
    const {
      user: { token },
      match: { params },
      getTicket,
    } = this.props;
    const ticket_id = params.ticket_id;

    if (ticket_id && ticket_id !== "add") {
      this.setState({ isAddTicket: false });
      getTicket(ticket_id, token)
        .then(() => {
          if (!ticket_id) {
            this.setState({ isFound: false });
          } else {
            const eventId = this.props.match.params.id;
            return this.props.getTemplates(eventId, false);
          }
        })
        .then(() => {
          const props = this.props;

          const pdfTemplateNameOption = props.currentTicket.pdfTemplateId
            ? {
                value: props.currentTicket.pdfTemplateId || 0,
                label: props.currentTicket.pdfTemplateName,
              }
            : props.pdfTemplates.find(
              ({ value }) => value === props.currentEvent.pdfTemplateId,
            );

          this.setState({
            ...props.currentTicket,
            pdfTemplateNameOption,
            count: props.currentTicket.allowedScansAmount,
            ticketName: props.currentTicket.ticketName,
            seatsIoCategoryKey: props.currentTicket.seatsIoCategoryKey,
            hasSeats: props.currentTicket.hasSeats,
            ticketPrice: props.currentTicket.price,
            ticketRules: props.currentTicket.ticketRules,
            ticketId: props.currentTicket.id,
            capacity: props.currentTicket.capacity,
          })
        });
    } else {
      const eventId = this.props.match.params.id;
      this.props.getTemplates(eventId, false);
    }
  };

  static getDerivedStateFromProps(props, state) {
    const pdfTemplateNameOption = props.currentTicket.pdfTemplateId
        ? {
            value: props.currentTicket.pdfTemplateId || 0,
            label: props.currentTicket.pdfTemplateName,
          }
        : props.pdfTemplates.find(
          ({ value }) => value === props.currentEvent.pdfTemplateId,
        );

    if (
      !state.isAddTicket &&
      props.currentTicket.id &&
      props.currentTicket.id !== state.ticketId
    ) {
      return {
        ...props.currentTicket,
        pdfTemplateNameOption,
        count: props.currentTicket.allowedScansAmount,
        ticketName: props.currentTicket.ticketName,
        seatsIoCategoryKey: props.currentTicket.seatsIoCategoryKey,
        hasSeats: props.currentTicket.hasSeats,
        ticketPrice: props.currentTicket.price,
        ticketRules: props.currentTicket.ticketRules,
        ticketId: props.currentTicket.id,
        capacity: props.currentTicket.capacity,
      };
    } else if (state.isAddTicket) {
      return {
        pdfTemplateNameOption
      }
    };

    return null;
  }

  isValid = () => {
    let validationMessage = null;
    let { ticketName, ticketPrice, capacity, ticketRules } = this.state;
    ticketPrice = parseInt(ticketPrice);
    capacity = parseInt(capacity);
    if (ticketName.trim().length === 0) {
      validationMessage = "Ticket name shouldn't be empty";
    }
    if (isNaN(ticketPrice)) {
      validationMessage = "Ticket price shouldn't be empty";
    }

    ticketRules.forEach((rule) => {
      if (rule.status !== 'delete') {
        if (rule.description.trim().length === 0) {
          validationMessage = 'Please add some description for ticket rule';
        }
        if (rule.description.length > 255) {
          validationMessage =
            'Maximum description length of ticket rule is 255 characters';
        }
        if (rule.startDate === null || rule.endDate === null) {
          validationMessage = 'Please add date to ticket rule';
        } else {
          if (moment(rule.endDate).isBefore(rule.startDate)) {
            validationMessage =
              'Ticket rule end date should be later then start date';
          }
        }
      }
    });

    if (validationMessage) {
      Swal({
        title: 'Error!',
        text: validationMessage,
        type: 'error',
        timer: 5000,
      });
      return false;
    }

    return true;
  };

  saveHandler = ({ currentTarget }) => {
    const {
      ticketName,
      ticketPrice,
      count,
      ticketRules,
      isAddTicket,
      capacity,
      seatsIoCategoryKey,
      pdfTemplateNameOption,
      hasSeats,
      entranceName,
      isAvailableToSale,
      isAvailableToResale,
    } = this.state;
    if (this.isValid()) {
      if (isAddTicket) {
        if (ticketName.length < 3 || ticketName.length > 120) {
          MySwal.fire({
            title: 'Error!',
            text: 'The field Name must be a string with a minimum length of 3 and a maximum length of 30.',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            type: 'error',
            timer: 3000,
          });
        } else {
          currentTarget.disabled = true;

          this.props
            .createTicket(
              {
                isAvailableToResale,
                isAvailableToSale,
                entranceName,
                hasSeats,
                pdfTemplateId: pdfTemplateNameOption.value,
                seatsIoCategoryKey,
                eventId: this.props.match.params.id,
                ticketName: ticketName,
                price: ticketPrice,
                allowedScansAmount: count,
                capacity: capacity,
              },
              this.props.user.token
            )
            .then((ticketId) => {
              return Promise.all(
                ticketRules.map((item) => {
                  item.ticketTemplateId = ticketId;
                  item.startDate = moment(item.startDate)
                    .utcOffset(0, true)
                    .format();
                  item.endDate = moment(item.endDate)
                    .utcOffset(0, true)
                    .format();
                  item.price = item.price === '' ? 0 : parseFloat(item.price);
                  return this.props.createTicketRule(
                    item,
                    this.props.user.token
                  );
                })
              );
            })
            .then(() => {
              const {
                user: { token },
                match: { params },
                getEvent,
              } = this.props;
              const event_id = params.id;
              getEvent(event_id, token).then((result) => {
                if (!result.isFound) {
                  this.setState({ isFound: false });
                }
              });
            })
            .then(() => {
              this.props.history.push(
                ROUTES.NEW_EVENT_TICKETS.replace(':id', this.props.match.params.id)
              );
            });
        }
      } else {
        if (ticketName.length < 3 || ticketName.length > 120) {
          MySwal.fire({
            title: 'Error!',
            text: 'The field Name must be a string with a minimum length of 3 and a maximum length of 30.',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            type: 'error',
            timer: 3000,
          });
        } else {
          this.props.updateTicket(
            {
              isAvailableToResale,
              isAvailableToSale,
              entranceName,
              hasSeats,
              seatsIoCategoryKey,
              pdfTemplateId: pdfTemplateNameOption.value,
              id: this.props.currentTicket.id,
              ticketName: ticketName,
              price: ticketPrice,
              allowedScansAmount: count,
              capacity: capacity,
            },
            this.props.user.token
          )
          .then(() => {
            const {
              user: { token },
              match: { params },
              getEvent,
            } = this.props;
            const event_id = params.id;
            getEvent(event_id, token).then((result) => {
              if (!result.isFound) {
                this.setState({ isFound: false });
              }
            });
          });
        }
        ticketRules.forEach((rule) => {
          if (rule.status === 'new') {
            rule.ticketTemplateId = parseInt(this.props.match.params.ticket_id);
            rule.startDate = moment(rule.startDate).utcOffset(0, true).format();
            rule.endDate = moment(rule.endDate).utcOffset(0, true).format();
            rule.price = rule.price === '' ? 0 : parseFloat(rule.price);
            this.props.createTicketRule(rule, this.props.user.token);
          }
          if (rule.status === 'delete') {
            this.props.removeTicketRule(rule.id, this.props.user.token);
          }
          if (rule.status === 'update') {
            rule.startDate = moment(rule.startDate).utcOffset(0, true).format();
            rule.endDate = moment(rule.endDate).utcOffset(0, true).format();
            rule.price = rule.price === '' ? 0 : parseFloat(rule.price);
            this.props.updateTicketRule(rule, this.props.user.token);
          }
        });
      }
    }
  };

  createTicketRuleHandler = () => {
    const { ticketRules } = this.state;
    ticketRules.push({
      id: 'a' + getRandomArbitrary(100000, 999999),
      startDate: null,
      endDate: null,
      description: '',
      price: '',
      status: 'new',
      count: 0,
    });

    this.setState({ ticketRules });
  };

  handleRuleChange = (id, type, value) => {
    const ticketRules = this.state.ticketRules.map((rule) => {
      if (rule.id === id) {
        if (type === 'price') {
          value = numberNormalize(value);
        }
        rule[type] = value;
        if (!rule.status) {
          rule.status = 'update';
        }
      }

      return rule;
    });
    this.setState({ ticketRules });
  };

  removeTicketRule = (id) => {
    if (this.state.isAddTicket) {
      this.setState({
        ticketRules: this.state.ticketRules.filter(
          (ticket) => ticket.id !== id
        ),
      });
    } else {
      this.setState({
        ticketRules: this.state.ticketRules.map((ticket) => {
          if (ticket.id === id) ticket.status = 'delete';
          return ticket;
        }),
      });
    }
  };

  removeHandler = () => {
    MySwal.fire({
      title: 'Are you sure that you want to delete this ticket?',
      text: "It's impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showConfirmButton: true,
      confirmButtonText: 'Delete',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.props
          .removeTicket(this.props.match.params.ticket_id, this.props.user.token)
          .then(() => {
            const {
              user: { token },
              match: { params },
              getEvent,
            } = this.props;
            const event_id = params.id;
            getEvent(event_id, token).then((result) => {
              if (!result.isFound) {
                this.setState({ isFound: false });
              }
            });
          })
          .then(() => {
            this.props.history.push(
              ROUTES.NEW_EVENT_TICKETS.replace(':id', this.props.match.params.id)
            );
          });
      }
    });
  };

  render() {
    if (!this.state.isFound) {
      return <Redirect to='/not-found' />;
    }

    const { currentTicket, match } = this.props;
    const {
      isAddTicket,
      ticketPrice,
      ticketName,
      ticketRules,
      capacity,
      seatsIoCategoryKey,
      pdfTemplateNameOption,
      hasSeats,
      entranceName,
      isAvailableToSale,
      isAvailableToResale,
    } = this.state;

    if (this.props.loading) {
      return (
        <div className='event-ticket'>
          <NewLoader />
        </div>
      );
    }

    return (
      <div className='event-ticket'>
        <div className='event-ticket__row'>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <label>Ticket name</label>
              <input
                placeholder='Enter ticket name'
                type='text'
                minLength='3'
                maxLength='120'
                value={ticketName}
                onChange={this.ticketNameChangeHandler}
              />
            </div>
            {!isAddTicket && (
              <div className='all-and-scanned'>
                <div className='add-event__column'>
                  <div className='add-event__block'>
                    <label>All tickets</label>
                    <span className='add-event__block__count'>
                      {isAddTicket ? 0 : currentTicket.allTickets}
                    </span>
                  </div>
                  <div className='add-event__block'>
                    <label>Scanned tickets</label>
                    <span className='add-event__block__count'>
                      {isAddTicket ? 0 : currentTicket.scannedTickets}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='add-event__row '>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <label>Price</label>
              <div className='counter'>
                <input
                  placeholder='Enter price'
                  type='text'
                  value={ticketPrice}
                  onChange={this.inputChangeHandle}
                />
              </div>
            </div>
          </div>
          <div className='add-event-column'>
            <div className='add-event__block scan-counter'>
              <Counter
                label='How many times ticket can be scanned'
                value={this.state.count}
                onValueChange={this.handleChangeCount}
              />
            </div>
          </div>
        </div>

        <div className='add-event__row'>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <div className='counter'>
                <CheckBox
                  name='hasSeats'
                  id='hasSeats'
                  checked={hasSeats}
                  onChange={this.checkboxChange}
                >
                  Has seats
                </CheckBox>
              </div>
            </div>
          </div>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <div className='counter'>
                <CheckBox
                  name='isAvailableToSale'
                  id='isAvailableToSale'
                  checked={isAvailableToSale}
                  onChange={this.checkboxChange}
                >
                  Available to sale
                </CheckBox>
              </div>
            </div>
          </div>
        </div>

        <div className='add-event__row'>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <div className='counter'>
                <CheckBox
                  name='isAvailableToResale'
                  id='isAvailableToResale'
                  checked={isAvailableToResale}
                  onChange={this.checkboxChange}
                >
                  Available to resale
                </CheckBox>
              </div>
            </div>
          </div>
        </div>

        <div className='add-event__row'>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <label>Capacity</label>
              <div className='counter'>
                <input
                  placeholder='Enter capacity'
                  type='text'
                  value={capacity}
                  onChange={this.inputCapacityChangeHandle}
                />
              </div>
            </div>
          </div>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <label>Key</label>
              <div className='counter'>
                <input
                  placeholder='Enter key'
                  type='text'
                  value={seatsIoCategoryKey}
                  onChange={this.inputSeatsIoCategoryKeyChangeHandle}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='add-event__row'>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <label>PDF template</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={getPdfTemplatesAsyncFunc(match.params.id, false)}
                value={pdfTemplateNameOption}
                onChange={this.inputPdfTemplateNameChangeHandle}
                classNamePrefix='custom-select'
                className='custom-select'
              />
            </div>
          </div>
          <div className='add-event__column'>
            <div className='add-event__block'>
              <label>Entrance</label>
              <div>
                <input
                  placeholder='Enter entrance'
                  type='text'
                  value={entranceName}
                  onChange={this.inputEntranceNameChangeHandle}
                />
              </div>
            </div>
          </div>
        </div>

        {ticketRules.length > 0 &&
          ticketRules.some((x) => x.status !== 'delete') && (
            <>
              <h3 className='ticket-rule__title'>Ticket rules</h3>

              <div className='rules'>
                {ticketRules.map((rule) => {
                  if (rule.status === 'delete') return null;
                  return (
                    <TicketRule
                      key={rule.id}
                      rule={rule}
                      onChange={this.handleRuleChange}
                      remove={this.removeTicketRule}
                    />
                  );
                })}
              </div>
            </>
          )}

        <div className='page-footer'>
          {!isAddTicket && (
            <>
              <button
                type='button'
                className='upload btn-secondary'
                onClick={this.goToUploadBarcodes}
              >
                <span>Upload barcodes</span>
              </button>
              <button
                type='button'
                className='upload btn-secondary'
                onClick={() => this.goToPage(ROUTES.NEW_GENERATE_PDFS)}
              >
                <span>Generate PDFs</span>
              </button>
              <button
                type='button'
                className='upload btn-secondary'
                onClick={() => this.goToPage(ROUTES.NEW_SEND_BUY_EMAIL)}
              >
                <span>Send offer</span>
              </button>
            </>
          )}
          <button
            type='button'
            className='btn-secondary'
            onClick={this.createTicketRuleHandler}
          >
            <Icon name='plus' />
            <span>Add ticket rule</span>
          </button>
          {!isAddTicket && (
            <button
              type='button'
              className='btn-red-outline'
              onClick={this.removeHandler}
            >
              <Icon name='trash' />
              <span>Delete</span>
            </button>
          )}
          <button
            type='button'
            className='btn-primary'
            onClick={this.saveHandler}
          >
            <Icon name='save' />
            <span>Save</span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, currentTicket, loading },
  canvas: { templates },
}) => ({
  user,
  currentEvent,
  currentTicket,
  loading,
  pdfTemplates: templates,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  createTicket: (data, token) => dispatch(createTicket(data, token)),
  createTicketRule: (data, token) => dispatch(createTicketRule(data, token)),
  getTicket: (data, token) => dispatch(getTicket(data, token)),
  updateTicket: (data, token) => dispatch(updateTicket(data, token)),
  removeTicket: (id, token) => dispatch(removeTicket(id, token)),
  updateTicketRule: (data, token) => dispatch(updateTicketRule(data, token)),
  removeTicketRule: (id, token) => dispatch(removeTicketRule(id, token)),
  clearCurrentEvent: () => dispatch(clearCurrentEvent()),
  getTemplates: (eventId, withEmptyOption) => dispatch(getTemplates(eventId, withEmptyOption)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketDetail));
