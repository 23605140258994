import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from "react-datepicker/es";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import moment from "moment";
import { salesAvailabilityOptions } from "../../../../../constants/salesAvailabilityOptions";
import { currencies } from "../../../../../constants/currencies";
import { getTimeZoneAsyncFunc } from "../../../../../helpers/getTimeZoneAsync";
import { getPdfTemplatesAsyncFunc } from "../../../../../helpers/getPdfTemplatesAsyncFunc";
import ROUTES from "../../../../../constants/routes";
import { makeElementsReadonly } from "../../../../../helpers/html";
import Page404 from "../../../../../components/Page404/Page404"
import CheckBox from "../../../../../components/UI/Checkbox/CheckBox";
import { Counter } from "../../../../../components/Counter/Counter";
import Icon from "../../../../../components/UI/Icon/Icon";
import { deleteEvent,
  getEvent,
  resetEventsError,
  updateEvent,
  updateHeaderImage,
  updateScannedTickets,
} from "../../../../../store/actions/eventsActions";
import { getTemplates } from "../../../../../store/actions/canvasActions";
import Dropzone from "../../../../Upload/Dropzone/Dropzone";

import "./EventInfo.sass";


const MySwal = withReactContent(swal);

class EventInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      currentEvent: {
        eventEmails: {
          emailTicketTemplateId: 0,
          emailOfferTemplateId: 0,
          emailGuestTemplateId: 0,
          emailErrorTemplateId: 0,
          emailInvoiceTemplateId: 0,
        },
        needUpdateWallets: true,
        timeZone: "",
        currency: {},
      },
      isDisabled: true,
      isFound: true,
      scannedTicketsStartDate: null,
      scannedTicketsEndDate: null,
      qrCodeVisibilityHours: 0,
      timeZoneOption: {},
      currentPdfTemplate: {
        value: null,
        label: "",
      }
    };
  };

  

  static getDerivedStateFromProps(props, state) {
    if (
      (props.currentEvent.id &&
      props.currentEvent.id !== state.currentEvent.id) ||
      !state.currentPdfTemplate
    ) {
      const currentEvent = {
        ...props.currentEvent,
        salesAvailability: salesAvailabilityOptions.find(
          (o) => o.value === props.currentEvent.salesAvailability
        ),
        currency: currencies.find(
          (option) => option.value === props.currentEvent.currency
        ),
        startDate: props.currentEvent.startDate,
        endDate: props.currentEvent.endDate,
        extraFeeRate: props.currentEvent.extraFeeRate * 100
      };
      return {
        currentEvent,
        timeZoneOption: {
          value: props.currentEvent.timeZone,
          label: props.currentEvent.timeZone,
        },
        currentPdfTemplate: props.pdfTemplates.find(
          ({ value }) => value === props.currentEvent.pdfTemplateId,
        ),
      };
    };
    return null;
  };

  handleUploadFile = (files) => {
    this.props.updateHeaderImage(
      this.props.user.token,
      this.state.currentEvent.id,
      files
    );
  };

  handleChangeEventField = (name, value) => {
    const { ...currentEvent } = this.state.currentEvent;
    currentEvent[name] = value;

    if (this.props.error) {
      this.props.resetEventsError();
    };

    if (name === "fixedFeeRate" || name === "extraFeeRate") {
      if (value === "-") {
        this.setState({
          currentEvent,
          isDisabled: true,
        });
      } else {
        this.setState({
          currentEvent,
          isDisabled: false,
        });
      };

      return;
    };

    if (value === "" || value === "-") {
      this.setState({
        currentEvent,
        isDisabled: true,
      });
    } else {
      this.setState({
        currentEvent,
        isDisabled: false,
      });
    };
  };

  changeEventInfo = (event) => {
    let { name, value } = event.target;

    if (name === "fixedFeeRate" || name === "extraFeeRate") {
      if (value[0] === "-") {
        value = "-" + value.replace(/[^\d.]/g, '');
      } else {
        value = value.replace(/[^\d.]/g, '');
      };

      if (name === "extraFeeRate" && (+value > 100 || +value < -100)) {
        return;
      }
    };

    this.handleChangeEventField(name, value);
  };

  inputTimeZoneChangeHandle = (value) => {
    this.setState({ timeZoneOption: value, isDisabled: false })
  };

  handleChangeDate = (name, value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent[name] = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  handleChangeCurrency = (value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent.currency = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  handleChangeSalesAvailability = (value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent.salesAvailability = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  handleChangeActivationTime = (value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent.activationTime = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  handleChangePdfTemplate = (value) => {
    this.setState({
      currentPdfTemplate: value,
      isDisabled: false,
    });
  }

  deleteEvent = () => {
    const { currentEvent } = this.state;
    const { deleteEvent } = this.props;

    MySwal.fire({
      title: "Are you sure that you want to delete this event?",
      text: "It's impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "warning-modal",
    }).then((result) => {
      if (result.value) {
        deleteEvent(this.props.user.token, currentEvent.id).then((result) => {
          if (result && result.isSuccess) {
            this.props.history.push(ROUTES.EVENTS);
          }
        });
      }
    });
  };

  updateEvent = () => {
    const { currentEvent } = this.state;

    const updateSalesAvailabilityData = {
      salesAvailability: currentEvent.salesAvailability.value,
      activationTime: moment(currentEvent.activationTime)
        .utcOffset(0, true)
        .format(),
    };

    const data = {
      ...updateSalesAvailabilityData,
      slug: currentEvent.slug,
      isDeactivate: false,
      name: currentEvent.name,
      startDate: moment(currentEvent.startDate).utcOffset(0, true).format(),
      endDate: moment(currentEvent.endDate).utcOffset(0, true).format(),
      currency: currentEvent.currency.value,
      fixedFeeRate: +currentEvent.fixedFeeRate || 0,
      extraFeeRate: (+currentEvent.extraFeeRate / 100) || 0,
      isCheckingCovid: currentEvent.isCheckingCovid,
      place: currentEvent.place,
      prefixLabel: currentEvent.prefixLabel,
      isAvailableForEarlySells: currentEvent.isAvailableForEarlySells,
      maxSeatsToSelectPerTransaction:
        currentEvent.maxSeatsToSelectPerTransaction,
      qrCodeVisibilityHours: currentEvent.qrCodeVisibilityHours,
      needUpdateWallets: currentEvent.needUpdateWallets,
      timeZone: this.state.timeZoneOption.value,
      pdfTemplateId: this.state.currentPdfTemplate.value
    };

    if (data.name.length < 2 || data.name.length > 500) {
      MySwal.fire({
        title: "Error!",
        text: "The field Name must be a string with a minimum length of 2 and a maximum length of 500.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        type: "error",
        timer: 3000,
      });
    } else if (data.slug.length < 2 || data.slug.length > 30) {
      MySwal.fire({
        title: "Error!",
        text: "The field slug must be a string with a minimum length of 2 and a maximum length of 30.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        type: "error",
        timer: 3000,
      });
    }
    else {
      this.updateEventWithData(data);
    }
  };

  updateEventWithData(data) {
    const { updateEvent, user } = this.props;
    const eventId = this.state.currentEvent.id;

    updateEvent(user.token, eventId, data)
      .then(() => this.props.getEvent(eventId, user.token));
  }

  componentDidMount() {
    makeElementsReadonly("react-datepicker__input-container");

    const eventId = this.props.match.params.id

    this.props.getTemplates(eventId, false);
  };

  render() {
    if (!this.state.isFound) {
      return <Page404 />;
    };

    const customSelectStyles = {
      control: (base) => ({
        ...base,
        height: "46px",
        "min-height": "46px",
        cursor: "pointer",
      }),
    };

    const {
      currentEvent, timeZoneOption, currentPdfTemplate
    } = this.state;

    const { currentEvent: { headerImageUrl }, match} = this.props;
    const classes = [];

    if (
      currentEvent.ticketTemplates &&
      currentEvent.ticketTemplates.length > 6
    ) {
      classes.push("scroll");
    };

    return (
      <div className="new-event__info info">
        <Dropzone
          imgUrl={headerImageUrl}
          onFilesAdded={this.handleUploadFile}
          onButtonEnable={() => { }}
          unsupportedFileFormatHandle={() => { }}
          onlyOneFileErrorHandle={() => { }}
        />

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Event name</label>
              <input
                type="text"
                name="name"
                value={currentEvent.name}
                placeholder="Enter event name"
                minLength="3"
                maxLength="500"
                onChange={this.changeEventInfo}
              />
            </div>
          </div>

          {currentEvent.seasonName && (
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Season</label>
                <span className="add-event__block__count">
                  {currentEvent.seasonName}
                </span>
              </div>
            </div>
          )}

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Venue</label>
              <input
                type="text"
                name="place"
                minLength="3"
                maxLength="500"
                placeholder="Enter venue"
                value={currentEvent.place}
                onChange={this.changeEventInfo}
              />
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Start</label>
              <DatePicker
                selected={this.state.currentEvent.startDate}
                onChange={(value) => this.handleChangeDate("startDate", value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="h:mm a d MMM yyyy "
                timeCaption="time"
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>End</label>
              <DatePicker
                selected={this.state.currentEvent.endDate}
                onChange={(value) => this.handleChangeDate("endDate", value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="h:mm a d MMM yyyy "
                timeCaption="time"
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Time Zone</label>

              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={getTimeZoneAsyncFunc()}
                value={timeZoneOption}
                onChange={this.inputTimeZoneChangeHandle}
                classNamePrefix='custom-select'
                className='custom-select'
              />
            </div>
          </div>
        </div>

        <div className="add-event__row add-event__row--width-divider">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Currency</label>

              <Select
                options={currencies}
                name="currency"
                classNamePrefix="add-event__select"
                value={currentEvent.currency}
                defaultValue={currentEvent.currency}
                onChange={(value) => this.handleChangeCurrency(value)}
                className="add-event__select"
                styles={customSelectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#6071B5",
                    primary25: "#F2F4FE",
                    primary50: "#F2F4FE",
                    primary75: "#F2F4FE",
                  },
                })}
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>
                {<Link style={{ margin: '0' }}
                  target='_blank'
                  to={ROUTES.SELLING.replace(':slug', currentEvent.slug).replace(':id', '')}>
                  Slug
                </Link>
                }
              </label>
              <input
                type="text"
                name="slug"
                value={currentEvent.slug}
                placeholder="Enter slug"
                minLength="3"
                maxLength="500"
                onChange={this.changeEventInfo}
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>PDF Template</label>

              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={getPdfTemplatesAsyncFunc(match.params.id, false)}
                value={currentPdfTemplate}
                onChange={this.handleChangePdfTemplate}
                classNamePrefix='custom-select'
                className='custom-select'
              />
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Sales availability</label>

              <Select
                options={salesAvailabilityOptions}
                name="salesAvailability"
                classNamePrefix="add-event__select"
                value={currentEvent.salesAvailability}
                defaultValue={currentEvent.salesAvailability}
                onChange={this.handleChangeSalesAvailability}
                className="add-event__select"
                styles={customSelectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#6071B5",
                    primary25: "#F2F4FE",
                    primary50: "#F2F4FE",
                    primary75: "#F2F4FE",
                  },
                })}
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Sales start time</label>

              <DatePicker
                selected={currentEvent.activationTime}
                onChange={this.handleChangeActivationTime}
                timeFormat="HH:mm"
                showTimeSelect
                timeIntervals={15}
                dateFormat="h:mm a d MMM yyyy "
                timeCaption="time"
                placeholderText="Select activation time"
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Allow members to buy tickets beforehand</label>

              <CheckBox
                name="memberSellAvailiability"
                id="memberSellAvailiability"
                checked={currentEvent.isAvailableForEarlySells}
                onChange={() => {
                  this.handleChangeEventField(
                    "isAvailableForEarlySells",
                    !currentEvent.isAvailableForEarlySells
                  )
                }
                }
              >
                {currentEvent.isAvailableForEarlySells ? 'Active' : 'Disable'}
              </CheckBox>
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <Counter
                label="Number of tickets in one transaction"
                value={currentEvent.maxSeatsToSelectPerTransaction}
                onValueChange={(value) =>
                  this.handleChangeEventField(
                    "maxSeatsToSelectPerTransaction",
                    value
                  )
                }
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Category</label>

              <input
                type="text"
                name="prefixLabel"
                minLength="3"
                maxLength="500"
                placeholder="Enter event category"
                value={currentEvent.prefixLabel}
                onChange={this.changeEventInfo}
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <Counter
                min="0"
                label={currentEvent.qrCodeVisibilityHours > 0 ? `QR-code will be visible in ${currentEvent.qrCodeVisibilityHours} hours before event` : "QR-code will be visible any time"}
                value={currentEvent.qrCodeVisibilityHours}
                onValueChange={(value) =>
                  this.handleChangeEventField(
                    "qrCodeVisibilityHours",
                    value
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Fixed fee rate, {currentEvent.currency.label}</label>
              <input
                type="text"
                name="fixedFeeRate"
                value={currentEvent.fixedFeeRate}
                placeholder="Enter fixed fee rate"
                minLength="3"
                maxLength="500"
                onChange={this.changeEventInfo}
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Extra fee rate, %</label>
              <input
                type="text"
                name="extraFeeRate"
                value={currentEvent.extraFeeRate}
                placeholder="Enter extra fee rate"
                minLength="3"
                maxLength="500"
                onChange={this.changeEventInfo}
              />
            </div>
          </div>
        </div>

        <div className="page-footer">
          <CheckBox
            name="isNeedBookSeats"
            id="is-need-book-seats"
            checked={currentEvent.needUpdateWallets}
            onChange={(e) => {
              this.handleChangeEventField(
                "needUpdateWallets",
                e.target.checked
              )
            }
            }
          >
            Update wallets
          </CheckBox>

          <button
            className="btn-red-outline"
            type="button"
            onClick={this.deleteEvent}
          >
            <Icon name="trash" />
            <span>Delete</span>
          </button>

          <button
            type="button"
            className="btn-primary btn-primary--save"
            onClick={this.updateEvent}
            disabled={this.state.isDisabled}
          >
            <Icon name="save" />
            <span>Save</span>
          </button>
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, loading, error },
  canvas: { templates, isTemplatesLoading },
}) => ({ user, currentEvent, loading, error, pdfTemplates: templates, isTemplatesLoading });

const mapDispatchToProps = (dispatch) => ({
  updateHeaderImage: (token, eventId, files) =>
    dispatch(updateHeaderImage(token, eventId, files)),
  updateScannedTickets: (eventId, token, start, end) =>
    dispatch(updateScannedTickets(eventId, token, start, end)),
  updateEvent: (userToken, eventId, data) =>
    dispatch(updateEvent(userToken, eventId, data)),
  deleteEvent: (userToken, eventId) =>
    dispatch(deleteEvent(userToken, eventId)),
  resetEventsError: () => dispatch(resetEventsError()),
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  getTemplates: (eventId, withEmptyOption) => dispatch(getTemplates(eventId, withEmptyOption)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);;