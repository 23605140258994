import React, { Component } from "react";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnLink,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
  createDropdown,
} from 'react-simple-wysiwyg';

import { connect } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import ls from 'localstorage-slim';
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import postFileToServer from "../../../../../helpers/postFileToServer";
import axios from "../../../../../axios-instance";
import { getHeaders } from "../../../../../helpers/getHeaders";
import Dropzone from "../../../../Upload/Dropzone/Dropzone";

import "./EventEmail.sass";
import "react-quill/dist/quill.snow.css";
import NewLoader from "../../../../../components/NewLoader/NewLoader";
import { editEmailTemplate, getEmailTemplates, resetState } from "../../../../../store/actions/emailTemplatesActions";
import { getTemplateTextValues } from "../../../../../store/actions/canvasActions";

const MySwal = withReactContent(Swal);

class EmailEditor extends Component {
  state = {
    currentTemplate: {
      value: null,
      label: '',
      item: {
        headerImageUrl: null,
        subject: '',
        bodyContent: '',
      }
    },
    isHeaderImageUploading: false,
    eventId: this.props.match.params.id,
    isEdited: false,
    isDataValid: true,
    templateValues: [{ value: "", label: "" }],
    color: "",
  };

  colorPickerRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (!state.currentTemplate.value && props.templates.length > 0) {
      return {
        currentTemplate: { ...props.templates[0] },
      };
    }
    return null;
  };

  handleChangeTemplate = (value) => {
    if (this.state.isEdited) {
      MySwal.fire({
        title: "Are you sure you want to select another template?",
        text: "All unsaved changes will be deleted",
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Select"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        }
      });
    };

    this.setState({
      currentTemplate: value,
      isEdited: false,
      isDataValid: true,
    });
  };

  handleChangeTemplateField = (name, value) => {
    const { ...item } = this.state.currentTemplate.item;

    item[name] = value;
    const currentTemplate = { ...this.state.currentTemplate, item };

    this.setState({ currentTemplate, isEdited: true });
  };

  handleBodyContentChange = (e) => {
    this.handleChangeTemplateField('bodyContent', e.target.value);
  }

  handleChangeImage = (files, fieldName) => {
    this.setState({ isHeaderImageUploading: true });

    postFileToServer("/EmailTemplate/Image", files[0])
    .then((response) => {
      const imageUrl = response.data.result;

      this.handleChangeTemplateField(fieldName, imageUrl);
    })
    .catch((e) => console.error(e))
    .finally(() => {
      this.setState({ isHeaderImageUploading: false });
    });
  };

  componentDidMount() {
    const { getEmailTemplates, getTemplateTextValues } = this.props;

    getEmailTemplates(this.state.eventId);
    getTemplateTextValues()
      .then((values) => {
        this.setState({ templateValues: values })
      })
      .catch(() => {
        toast.error("Template variables not defined");
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !!this.state.currentTemplate.item.subject &&
      !this.state.isDataValid
    ) {
      this.setState({ isDataValid: true });
    }
  };

  componentWillUnmount() {
    this.props.resetState();
  };

  updateTemplate = async () => {
    const { value, item } = this.state.currentTemplate;
    const { editEmailTemplate } = this.props;

    if (!item.subject) {
      this.setState({ isDataValid: false });

      return;
    };

    try {
      await editEmailTemplate(value, item, this.state.eventId);

      this.setState({
        isEdited: false,
      });
    } catch (err) {
      Swal({
        title: "Error!",
        text: err,
        type: "error",
        timer: 3000,
      });
    };
  };

  sendSampleRequest = async (email) => {
    try {
      const user = ls.get("user");
      const data = this.state.currentTemplate.item;
      this.setState({ isSampleSending: true });

      const response = await axios
        .post(`/EmailTemplate/SendSampleByRequest/${email}`, data, {
          headers: getHeaders(user.token),
        });

      if (response.data.isSuccess) {
        toast.success("Email successfully sent");
        this.setState({ isSampleSending: false });

        return response;
      };

      throw new Error(response.data.errorMessage);
    } catch (error) {
      console.log(error);
      
      this.setState({ isSampleSending: false });
      Swal({
        title: "Error!",
        text: error,
        type: "error",
        timer: 3000,
      });
    }
  };

  handleClickSendSample = async () => {
    const { value: email } = await Swal.fire({
      title: "Send sample email",
      input: "email",
      inputValue: ls.get("lastEmailforEditor") || "",
      inputLabel: "Email address",
      inputPlaceholder: "Email address",
      confirmButtonText: "Send",
      reverseButtons: true,
      showCancelButton: true,
    });

    if (email) {
      ls.set("lastEmailforEditor", email);

      this.sendSampleRequest(email);
    };
  };

  render() {
    const { templates, isEmailTemplatesLoading } = this.props;
    const {
      currentTemplate,
      isDataValid,
      isSampleSending,
      templateValues,
    } = this.state;

    const { headerImageUrl, subject, bodyContent } = currentTemplate.item;

    const variablesList = templateValues.map((v => (
      [v.label, 'insertText', v.value]
    )))

    const BtnInsert = createDropdown('Text value', variablesList);
    const BtnFontColor = createButton('Color', '', (val) => {
      document.execCommand('foreColor', false, prompt('HEX Color', '') || undefined);
      console.log(val)
    });

    const customSelectStyles = {
      control: (base) => ({
        ...base,
        height: "46px",
        "min-height": "46px",
        cursor: "pointer",
      }),
    };

    if (isEmailTemplatesLoading) {
      return (
        <div className="email">
          <NewLoader />
        </div>
      );
    }

    return (
      <div className="new-event__email email">
        <div className="email__block">
          <label>Template</label>
          <Select
            options={templates}
            name="templates"
            classNamePrefix="email__select"
            value={currentTemplate}
            defaultValue={currentTemplate}
            onChange={(value) => this.handleChangeTemplate(value)}
            className="email__select"
            styles={customSelectStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#6071B5",
                primary25: "#F2F4FE",
                primary50: "#F2F4FE",
                primary75: "#F2F4FE",
              },
            })}
          />
        </div>

        <div className="email__block">
          <label>Mail subject</label>

          <input
            type="text"
            name="subject"
            value={subject}
            minLength="3"
            maxLength="500"
            onChange={(e) => this.handleChangeTemplateField(e.target.name, e.target.value)}
            className={`email__textfield ${!isDataValid ? 'email__textfield--is-error' : ''}`}
            placeholder="Mail subject"
          />

          {!isDataValid && (
            <p className="email__error-message">
              This is a required field
            </p>
          )}
        </div>

        <div className="email__block">
          <label>Mail header</label>

          {this.state.isHeaderImageUploading ? (
            <div className="email__image-loader">
              <NewLoader /> 
            </div>
          ): (
            <Dropzone
              imgUrl={headerImageUrl}
              onFilesAdded={(files) => this.handleChangeImage(files, 'headerImageUrl')}
              onButtonEnable={() => { }}
              unsupportedFileFormatHandle={() => { }}
              onlyOneFileErrorHandle={() => { }}
            />
          )}
        </div>

        <div className="email__content-block">
          <EditorProvider>
            <Editor
              value={bodyContent}
              onChange={this.handleBodyContentChange}
            >
            <Toolbar>
              <BtnUndo />
              <BtnRedo />
              <Separator />
              <BtnBold />
              <BtnItalic />
              <BtnUnderline />
              <BtnStrikeThrough />
              <BtnFontColor />
              <Separator />
              <BtnNumberedList />
              <BtnBulletList />
              <Separator />
              <BtnLink />
              <BtnClearFormatting />
              <HtmlButton />
              <Separator />
              <BtnStyles />
              <BtnInsert />
            </Toolbar>
            </Editor>
          </EditorProvider>
        </div>

        <div className="email__buttons-container">
          <div className="email__button">
            {isSampleSending ? <NewLoader /> : (
              <button
                type="button"
                className="btn-primary btn-primary--reselling"
                onClick={this.handleClickSendSample}
                disabled={isSampleSending}
              >
                Send sample
              </button>
            )}
          </div>

          <div className="email__button">
            <button
              type="button"
              className="btn-primary btn-primary--reselling"
              onClick={this.updateTemplate}
              disabled={!this.state.isEdited}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  emailTemplates: { currentTemplate, templates, isEmailTemplatesLoading },
}) => {
  return {
    currentTemplate,
    templates,
    isEmailTemplatesLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEmailTemplates: (eventId) => dispatch(getEmailTemplates(eventId)),
  editEmailTemplate: (templateId, template, eventId) => dispatch(editEmailTemplate(templateId, template, eventId)),
  resetState: () => dispatch(resetState()),
  getTemplateTextValues: () => dispatch(getTemplateTextValues())
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailEditor);
