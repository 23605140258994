import React from 'react';
import {Link} from 'react-router-dom';

import './Registration.sass';
import ROUTES from '../../../constants/routes';

const RegistrationSuccess = () =>
    <div className="auth login">
        <img className="auth__logo" src="/images/img-logo.svg" alt="SixTix logo"/>
        <div className="auth__title">
            <p>Thank you for registration!</p>
            <p>Please click the activation link that has been sent to your email.</p>
            <div className="sign-in">
                <Link to={ROUTES.LOGIN}>Sign in</Link>
            </div>
        </div>
    </div>;

export default RegistrationSuccess;

